const conf = {
  apiKey: 'AIzaSyAthlunGcJlubo_pn99R5diXsTNYtN_wDk',
  authDomain: 'app.upstager.co',
  databaseURL: 'https://upstager-live.firebaseio.com',
  projectId: 'upstager-live',
  storageBucket: 'upstager-live.appspot.com',
  messagingSenderId: '131737717587',
  appId: '1:131737717587:web:99ab0aa49e25655fb5afa6',
  measurementId: 'G-B4WBP8NNYY',
  applicationKey: '9wbhwezsa3eh1yg6a7akc8ssd',
  customerAppUrl: 'https://customer.upstager.co',
  adminAppUrl: 'https://admin.upstager.co',
  internalVersion: '2',
  isDev: false,
  backendUrl: 'https://us-central1-upstager-live.cloudfunctions.net',
  userAppUrl: 'https://app.upstager.co',
  designerAppUrl: 'https://designer.upstager.co',
  sentryCustomerDSN:
    'https://2d081fe1778e4ee4ba0808daf7054c2b@o4505011503759360.ingest.sentry.io/4505011536330752',
  sentryDesignerDSN:
    'https://f2257e297d334dd9acbb4d641f748641@o4505011503759360.ingest.sentry.io/4505016607244288',
  sentryUserDSN:
    'https://03a704cf801346fdb8d1a3af017222bb@o4505011503759360.ingest.sentry.io/4505016671797248',
  sentryAdminDSN:
    'https://432c2a7c29204b26a36ca20113400a0a@o4505011503759360.ingest.sentry.io/4505016687591424'
}

export default conf
