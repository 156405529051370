import { FC, useMemo, MouseEvent } from 'react'
import { TourModelT } from 'types/model'
import _ from 'lodash'
import {
  WrapItem,
  Image,
  Flex,
  Text,
  IconButton,
  VStack,
  useClipboard,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useDisclosure,
  HStack
} from '@chakra-ui/react'
import {
  faTrashCan,
  faPen,
  faClipboard,
  faEllipsisV
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from 'shared/config'
import { noImageUrl } from 'shared/constants/helpers'
import { useSelector } from 'model/hooks'
import { getToursByModel } from 'model/selectors/tours'
import { useNavigate } from 'react-router'

type Props = {
  tm: TourModelT
  // onCreateDesignClick: (tourModelId: string) => void
  onEdit: () => void
  onDelete: () => void
}

const TourModelCard: FC<Props> = ({ tm, onEdit, onDelete }) => {
  const toursByModel = useSelector(getToursByModel)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { onCopy } = useClipboard(`${config.userAppUrl}/${tm.id}`)
  const navigate = useNavigate()

  const onDeleteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onDelete()
  }

  const onEditClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onEdit()
  }

  const onCopyClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onCopy()
  }

  const tours = useMemo(() => {
    return toursByModel[tm.id]
  }, [toursByModel, tm])

  const renderMenu = () => {
    return (
      <Menu isOpen={isOpen} onClose={onClose} placement='bottom-end'>
        <MenuButton
          as={IconButton}
          icon={<FontAwesomeIcon icon={faEllipsisV} />}
          variant={'ghost'}
          colorScheme={'blackAlpha'}
          size='lg'
          onClick={openMenu}
        />
        <MenuList zIndex='101'>
          <MenuItem
            color={'gray.500'}
            icon={<FontAwesomeIcon icon={faPen} fixedWidth />}
            onClick={onEditClick}
          >
            <Text color='gray.700' fontWeight={'semibold'}>
              Edit
            </Text>
          </MenuItem>
          <MenuItem
            color={'gray.500'}
            icon={<FontAwesomeIcon icon={faClipboard} fixedWidth />}
            onClick={onCopyClick}
          >
            <Text color='gray.700' fontWeight={'semibold'}>
              Copy link
            </Text>
          </MenuItem>
          <MenuItem
            color={'red.500'}
            icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
            onClick={onDeleteClick}
          >
            <Text color='red.500' fontWeight={'semibold'}>
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  const openMenu = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onOpen()
  }

  const isUpstaged = !_.isEmpty(tours)

  return (
    <WrapItem
      key={tm.id}
      w='sm'
      h='xs'
      position='relative'
      rounded={'xl'}
      // as='button'
      onClick={() => navigate(`/tour/${tm.id}`)}
      cursor='pointer'
    >
      <Image
        src={tm.photoUrl || noImageUrl}
        rounded={'xl'}
        w='sm'
        h='xs'
        objectFit={'cover'}
      />
      <Flex
        w='full'
        h='full'
        position='absolute'
        left={0}
        bottom={0}
        rounded={'xl'}
        // pt={6}
        direction='column'
        justify={'space-between'}
      >
        <Flex flex={1} justify='flex-end' p={2}>
          {renderMenu()}
        </Flex>

        <VStack
          align='flex-start'
          justify={'flex-end'}
          spacing={0}
          bgGradient={'linear(to-b, #00000000, #000000aa)'}
          p={6}
          rounded='xl'
          flex={2}
        >
          <Text
            color='white'
            fontWeight={'extrabold'}
            textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
          >
            {tm.name}
          </Text>
          {tm.address && (
            <>
              <Text
                color='white'
                fontWeight={'extrabold'}
                textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
              >
                {tm.address.structured.main}
              </Text>
              <Text
                color='white'
                fontWeight={'semibold'}
                fontSize='small'
                textShadow='rgba(0, 0, 0, 0.2) 1px 1px 1px'
              >
                {tm.address.city}, {tm.address.stateAbbr}
              </Text>
            </>
          )}
          <HStack pt={6} justify='flex-start' w='full' spacing={6}>
            <Box
              borderColor={isUpstaged ? 'green.300' : 'red'}
              borderWidth={1}
              rounded='base'
              px={4}
            >
              <Text
                color={isUpstaged ? 'green.200' : 'red'}
                fontWeight='semibold'
              >
                {isUpstaged ? 'Upstaged' : 'Not Upstaged'}
              </Text>
            </Box>
            {/* <Tooltip
              label='Create a design for customers to view while exploring the space'
              hasArrow
            > */}
            {isUpstaged && (
              <Text
                variant={'link'}
                color='white'
                fontSize='sm'
                fontWeight={'semibold'}
              >
                Designs: {_.size(tours)}
              </Text>
            )}
            {/* </Tooltip> */}
            {/* <Tooltip label='Open the Matterport model in a new tab' hasArrow>
              <IconButton
                color='white'
                aria-label='view tour'
                icon={<FontAwesomeIcon icon={faEye} />}
                variant='link'
                onClick={() =>
                  window.open(
                    `https://my.matterport.com/show/?m=${tm.modelId}&play=1&ts=5&qs=1&brand=0&dh=1&gt=1&hr=1&mls=2&mt=0&pin=0&portal=0&f=1&lang=en&nozoom=0&wh=1&kb=1&lp=0&title=0&tourcta=0&vr=1`,
                    '_blank'
                  )
                }
              />
            </Tooltip> */}
          </HStack>
        </VStack>
      </Flex>
    </WrapItem>
  )
}

export default TourModelCard
